<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #e53935">จัดการข่าว</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark>เพิ่มข่าว</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersnews"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.blogImg`]="{ item }">
              <v-img :src="item.blogImg" width="120px" class="ma-1"></v-img>
            </template>
            <!-- <template v-slot:[`item.description`]="{ item }">
              <span v-html="item.description"></span>
            </template> -->
            <template v-slot:[`item.activeFlag`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.activeFlag == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.activeFlag == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="Viewnews(item)">mdi-eye</v-icon> -->
                <v-icon @click="Updatenews(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="Deletenews(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headersnews: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพ", value: "blogImg", align: "center" },
        { text: "ชื่อข่าว", value: "title", align: "center" },
        // { text: "รายละเอียด", value: "description", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [
        // { category: "promotion", description: "สินค้าจัดโปร" },
        // { category: "discount items", description: "สินค้าลดราคา" },
        // { category: "Shock price", description: "สินค้าราคาถูก" },
        // { category: "สินค้ามาใหม่", description: "สินค้ามาใหม่" },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllnews();
  },
  methods: {
    async getAllnews() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/blogs`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllnews", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    Updatenews(val) {
      localStorage.setItem("newsdata", Encode.encode(val));
      this.$router.push("Editnews");
    },
    Viewnews(val) {
      localStorage.setItem("newsdata", Encode.encode(val));
      this.$router.push("Viewnews");
    },
    async Deletenews(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userSPM"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/blogs/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllnews();
        }
      });
    },
    goToCreate() {
      this.$router.push("createnews");
    },
  },
};
</script>
