<template>
  <div>
    <Managenews />
  </div>
</template>
<script>
import Managenews from "@/components/news/Managenews";
export default {
  components: {
    Managenews,
  },
  created() {
  },
};
</script>